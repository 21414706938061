export const formatDate = mills => {
    const date = new Date(mills);
    const day = date.getDate()
    const month = date.getMonth()+1
    const year = date.getFullYear()
    return setZero(day)+'/'+setZero(month)+'/'+year.toString()
  }

export const setZero = (dd) => {
      let d = dd.toString()
      if (dd < 10) d = '0'+d
      return d
  }