import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Icon } from "react-icons-kit"
import { edit } from "react-icons-kit/fa/edit"
import AdmNavbar from "../../components/admNavbar"
import Footer from "../../components/footer"
import { getTutorialsPage, deleteTutorialsEntries } from "../../services/admin"
import { VerifyUserPermission } from "../../functions/verifyUserPermision"
import { formatDate } from "../../functions/formatDate"
import "../../css/multilogica-theme.scss"

import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Table,
  Modal,
} from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"

function AdminTutorialsList() {
  const pageNumber = 0

  const [userVerified, setUserVerified] = useState(false)
  const [jwt, setJwt] = useState("")
  const [tutorialsData, setTutorialsData] = useState([])
  const [selectedTutorials, setSelectedTutorials] = useState([])
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false)
  const [showDelSppiner, setShowDelSppiner] = useState(false)
  const [rebuildTable, setRebuildTable] = useState(false)

  useEffect(() => {
    setJwt(localStorage.getItem("jwt"))
  }, [])

  useEffect(() => {
    const getTutorialsData = async () => {
      const data = {
        pageNumber: pageNumber,
        permission: "view-tutorials-list",
      }
      if (jwt !== "") {
        await getTutorialsPage(jwt, data).then(res => {
          if (res.status === 201) {
            const sortedRes = res.data.sort((f, s) => {
              if (f.publicationDate > s.publicationDate) {
                return -1
              } else {
                return 1
              }
            })
            setTutorialsData(sortedRes)
          } else {
            console.log("erro", res)
          }
        })
      }
    }
    getTutorialsData()
    setSelectedTutorials([])
    setRebuildTable(false)
  }, [jwt, setTutorialsData, rebuildTable])

  useEffect(() => {
    const verifyUser = async () => {
      const userHasPermission = await VerifyUserPermission("use-img-manager")
      if (!userHasPermission) {
        navigate("/403")
      } else {
        setUserVerified(true)
      }
    }
    if (!userVerified) verifyUser()
  }, [userVerified])

  const toggleCheck = evt => {
    const selTutorials = selectedTutorials
    if (evt.target.checked) {
      if (!selTutorials.includes(evt.target.id)) {
        selTutorials.push(evt.target.id)
      }
    } else {
      if (selTutorials.includes(evt.target.id)) {
        selTutorials.splice(selTutorials.indexOf(evt.target.id), 1)
      }
    }
    setSelectedTutorials(selTutorials)
  }

  const askForDeleteTutorials = () => {
    if (selectedTutorials.length > 0) {
      setModalDeleteConfirm(true)
    }
  }

  const removeModal = () => {
    setModalDeleteConfirm(false)
  }

  const gotoContentEditor = () => {
    navigate("/admin/tutorials-editor")
  }

  const deleteTutorials = async () => {
    setModalDeleteConfirm(false)
    setShowDelSppiner(true)
    if (jwt !== "") {
      const data = {
        tutorialsTodelete: selectedTutorials,
        permission: "delete-tutorials-entry",
      }
      await deleteTutorialsEntries(jwt, data).then(res => {
        setShowDelSppiner(false)
        if (res.status === 201) {
          setRebuildTable(true)
        } else {
          console.log("erro", res)
        }
      })
    }
  }

  const editTutorials = (evt, id) => {
    localStorage.setItem("editingTutorial", JSON.stringify(tutorialsData[id]))
    console.log(JSON.stringify(tutorialsData[id]))
    navigate("/admin/tutorials-editor")
  }

  const getTutorialsList = () => {
    if (tutorialsData.length) {
      return (
        <Table striped responsive hover>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Título</th>
              <th>Publicado</th>
              <th>Publicação</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {tutorialsData.map((tutorialsLine, k) => {
              return (
                <tr key={k}>
                  <td>
                    <div className="custom-control custom-checkbox custom-checkbox-green">
                      <input
                        onClick={evt => toggleCheck(evt)}
                        onChange={evt => toggleCheck(evt)}
                        type="checkbox"
                        className="custom-control-input custom-control-input-green"
                        id={tutorialsLine.id}
                        // checked={
                        //   selectedBlogs.includes(blogLine.id) ? true : false
                        // }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={tutorialsLine.id}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>{tutorialsLine.title}</td>
                  <td>{tutorialsLine.publishedContent ? "✅" : "❌"}</td>
                  <td>{formatDate(tutorialsLine.publicationDate)}</td>
                  <td>
                    <Button size="sm" onClick={evt => editTutorials(evt, k)}>
                      <Icon size={"18px"} icon={edit} />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <AdmNavbar />
      <Modal show={modalDeleteConfirm}>
        <Modal.Body>
          <p>Corfirmar a remoção destas notícias:</p>
          {Array.isArray(tutorialsData) ? (
            tutorialsData.map((tutorialsLine, k) => {
              let ret
              if (selectedTutorials.includes(tutorialsLine.id)) {
                ret = <p key={k}>{tutorialsLine.title}</p>
              } else {
                ret = ""
              }
              return ret
            })
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button variant="danger" onClick={deleteTutorials}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="height-40" />
      <div>
        <Container>
          <Row>
            <Col>
              <Container className="info-page">
                <Row className="align-items-center">
                  <Col xs={12}>
                    <div className="msg">
                      <h1>Tutoriais</h1>
                    </div>
                  </Col>
                </Row>
                {!userVerified ? (
                  <div>
                    <Spinner animation="border" size="sm" variant="primary" />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Button size="sm" onClick={evt => gotoContentEditor()}>
                          Criar novo tutorial
                        </Button>
                        <div style={{ height: "20px" }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          size="sm"
                          onClick={evt => askForDeleteTutorials(evt)}
                          style={{ float: "left" }}
                        >
                          Apagar os tutoriais selecionados
                        </Button>
                        <div
                          style={{
                            display: showDelSppiner ? "block" : "none",
                            float: "left",
                            margin: "4px 0 0 6px",
                          }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                        <div style={{ height: "20px" }} />
                        <div style={{ height: "20px" }} />
                        {getTutorialsList()}
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="height-40" />
      <Footer />
    </>
  )
}

export default AdminTutorialsList
